@import url('https://fonts.googleapis.com/css2?family=Poppins');
@font-face {
    font-family: 'Futura PT';
    src: url('./components/UI/Typography/Fonts/Futura-PT-Heavy.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Prompt');

/* .App-header {
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
/* 
.App-link {
  color: #484A9A;
}

/* unvisited link 
a:link {
  color:#484A9A;
}

/* visited link 
a:visited {
  color: #484A9A;
}

/* mouse over link 
a:hover {
  color: #484A9A;
  text-transform: none;
}

/* selected link 
a:active {
  color: #484A9A;
  text-transform: none;
} */

/* table tr:nth-child(even){background-color: #f2f2f2;} */

/* Batch Record Timeline */
/* #Propagation-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#Propagation-table th, #Lime-table th {
  background-color: #CBDB2F;
  color: black;
  
}

#Vegetation-table th, #Green-table th {
  background-color: #00AC89;
  color: white;
}

#Flower-table th, #Teal-table th {
  background-color: #5BC6CC;
  color: white;
}

#Harvest-table th, #Purple-table th {
  background-color: #484A9A;
  color: white;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .navbar-custom {
  background-color:#395177;
  background-image: none;
  background-repeat: no-repeat;
 }

 .react-paginate ul {
  display: inline-block;
  margin-left: 20px;
  padding-left: 0;
}

.react-paginate li {
  display: inline-block;
  border: 1px solid rgb(224, 224, 224);
  color: #000;
  cursor: pointer;
  margin-right: 3px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.react-paginate li a {
    padding: 2px 5px;
    display: inline-block;
    color: #000;
    outline: none;
}

.react-paginate li.active {
  background: rgb(224, 224, 224);
  outline: none;
} */

@keyframes FadeAnim {
  0% {
     opacity: 0;
  }
  100% {
     opacity: 1;
  }
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  z-index: 2000;
  position: fixed;
  height: 90px;
  width: 90px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pace.pace-inactive .pace-activity {
  display: none;
}

.pace .pace-activity {
  position: fixed;
  z-index: 2000;
  display: block;
  position: absolute;
  left: -30px;
  top: -30px;
  height: 90px;
  width: 90px;
  display: block;
  border-width: 30px;
  border-style: double;
  border-color: #29d transparent transparent;
  border-radius: 50%;

  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  -o-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.pace .pace-activity:before {
  content: ' ';
  position: absolute;
  top: 10px;
  left: 10px;
  height: 50px;
  width: 50px;
  display: block;
  border-width: 10px;
  border-style: solid;
  border-color: #29d transparent transparent;
  border-radius: 50%;
}

@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(359deg); }
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(359deg); }
}

@-o-keyframes spin {
  100% { -moz-transform: rotate(359deg); }
}

@keyframes spin {
  100% {  transform: rotate(359deg); }
}

body > hmr-error-overlay {
    display: none;
}

body > iframe {
    display: none;
}
